import React from "react";
import logo from "../assets/aimllogo.svg";
// import { message } from "antd";
// import axios from "axios";
import "./Waitlist.css";
// import WaitlistModal from "./WaitlistModal/WaitlistModal";
import smallRectangle from "../assets/smallRectangle.svg";
// import TopPartner from "../assets/Neurobridge_LogoMark_RGB_Balck.svg";
import backgroundVideo from "../assets/video/InitialBackground.mp4";
import initialLoad from "../assets/onInitialload.webp";
import { useNavigate } from "react-router-dom";
// import Loader from "../Loader/Loader";
// import invalidEmail from "../assets/invalidEmail.svg";
// import arrowRight from "../assets/RightArrow.svg";
// import invalidEmail from "../assets/invalidEmail.svg";
// import Loader from "../Loader/Loader";

const Waitlist = () => {
  // const [showModal, setShowModal] = useState(false);
  // const [waitlistId, setWaitlistId] = useState("");
  // const [validEmail, setValidEmail] = useState(true);
  // const [emailRequired, setEmailRequired] = useState(false);
  // const [setLoading] = useState(false);
  // const [email, setEmail] = useState("");

  // const baseUrl = `https://uat.ai.ml/`;
  const navigation = useNavigate();

  const handleJoinWaitlist = async () => {
    const targetUrl = "https://console.ai.ml";
    window.open(targetUrl, "_blank");

    // setLoading(true);
    // if (!email.trim()) {
    //   setEmailRequired(true);
    //   setLoading(false);
    //   return;
    // }
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   // setEmail("");
    //   setValidEmail(false);
    //   setLoading(false);
    //   return;
    // } else {
    //   setValidEmail(true);
    // }
    // try {
    //   const response = await axios.post(`${baseUrl}apis/create-waitlist/`, {
    //     email: email,
    //   });
    //   if (response.data.status) {
    //     setWaitlistId(response.data.waitlist_id);
    //     setShowModal(true);
    //     setLoading(false);
    //   } else {
    //     message.error("Failed to join waitlist. Please try again later.");
    //     setLoading(false);
    //   }
    // } catch (error) {
    //   console.error("Error joining waitlist:", error);
    //   message.error(error.response.data.detail);
    //   setLoading(false);
    // }
    // setEmail("");
  };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  // const handleEmailChange = (event) => {
  // const email = event.target.value;
  // setEmail(email);
  // setEmailRequired(false);
  // if (!email.trim()) {
  //   setEmailRequired(true);
  //   setLoading(false);
  // } else {
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // setValidEmail(emailRegex.test(email));
  // }
  // };
  const handleJoinComminity = () => {
    const targetUrl = "https://community.ai.ml/home";
    window.open(targetUrl, "_blank");
  };
  const handlePrivacy = () => {
    navigation("/privacy");
  };
  const handleTOS = () => {
    navigation("/tos");
  };
  return (
    <div className="app-container">
      <video
        autoPlay
        muted
        loop
        playsInline
        id="background-video"
        preload="auto"
        poster={initialLoad}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div>
        <div className="waitlist-container">
          <div className="ai-ml-header">
            <div className="ai-mllogo">
              <img src={logo} alt="AI.ML" width={134} height={73} />
            </div>
            <div className="top-right-title">
              <p className="first-interface">
                <img src={smallRectangle} alt="indian" width={12} height={12} />{" "}
                GenAI Inference Platform
              </p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="top-heading-box">
              <h2 className="ai-ml-title">
                Empowering Builders <br className="dNone" />
                of the <span className="genAI">AI Economy</span>
              </h2>
              <p className="ai-ml-subtitle">
                The Ultimate Production AI Platform Tailored for Developers.
              </p>
            </div>
            <div className="input-wrapper">
              <div className="input-container">
                {/* <input
                type="text"
                className={
                  !validEmail || emailRequired
                    ? "form-control invalid"
                    : "form-control"
                }
                placeholder="Enter your email address"
                value={email}
                onChange={handleEmailChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleJoinWaitlist();
                  }
                }}
              /> */}
                {/* {!validEmail && !emailRequired && (
                <div className="invalid-email-icon">
                  <img src={invalidEmail} alt="email-invalid-icon" />
                </div>
              )}
              {(!validEmail || emailRequired) && (
                <span className="error-message">
                  {emailRequired
                    ? "Email ID is required."
                    : "Please enter a valid email address."}
                </span>
              )} */}
              </div>

              {/* <button className="join-button" onClick={handleJoinWaitlist}>
              {loading ? (
                <Loader size={22} marginTop={-5} color="#FFFFFF" />
              ) : (
                <div className="join-waitist-text">
                  Join Waitlist &nbsp;
                  {loading ? null : (
                    <img src={arrowRight} alt="arrow" className="arrow-right" />
                  )}
                </div>
              )}
            </button> */}
              <button className="join-button" onClick={handleJoinWaitlist}>
                <div className="join-waitist-text">Try now</div>
              </button>
              <button
                className="join-button arrow ml-md-4 my-4"
                onClick={handleJoinComminity}
              >
                <div className="join-waitist-text">Join Community</div>
              </button>
            </div>
            <div className="additional-info">
              <div className="waitlist-freeTokens">
                <p>
                  Try now and get &nbsp;
                  <span className="freeTokens">200K tokens - FOR FREE</span>
                </p>
              </div>
            </div>
            <div className="row efficient-info">
              <div className="col-md-4 info-card">
                <p>
                  3X <span className="info-span">Faster</span>
                </p>
              </div>
              <div className="col-md-4 info-card">
                <p>
                  100+ <span className="info-span">Tokens/sec</span>
                </p>
              </div>
              <div className="col-md-4 info-card">
                <p>
                  10X <span className="info-span">Lower Cost</span>
                </p>
              </div>
              <div className="col-md-12">
                <p className="info-paragraph">
                  Faster tokens, higher throughput, and lower latency. These
                  efficiencies mean cheaper compute.
                </p>
              </div>
            </div>
            {/* <div className="top-partner">
              <h3 className="section-title">Our Top Partner</h3>
              <div className="top-partner-card">
                <div className="logo-wrapper">
                  <img src={TopPartner} alt="logo" />
                </div>
                <div className="text-wrapper">
                  <h4 className="card-title">Neurobridge Tech </h4>
                  <p className="card-text">
                    Our trusted partner in building advanced API solutions.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <WaitlistModal
          visible={showModal}
          closeModal={closeModal}
          waitlistId={waitlistId}
        /> */}
        </div>
        <div className="rectangle-strip">
          <p className="rectangle-content">
            © 2024, NeevAI SuperCloud Private Limited
          </p>
          <div className="btn-box">
            <button className="footer-btn" onClick={handlePrivacy}>
              Privacy Policy
            </button>
            <button className="footer-btn" onClick={handleTOS}>
              Terms of Service
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
