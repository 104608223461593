import React from "react";
import { useNavigate } from "react-router-dom";
import Phone from "../assets/phone.svg";
import "./Layout.css";
const assetPath = "https://neurobridge-public.objectstore.e2enetworks.net";
const Logo = assetPath + "/aiml/logo.svg";
// const Logout = assetPath + "/aiml/logout.svg";

const LaoutTermsAndCondition = () => {
  // const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();

  const navigateAPIDoc = (tab) => {
    if (tab === "docs") {
      window.open("http://api.ai.ml/docs/swagger/", "_blank");
    } else {
      window.open("https://community.ai.ml/", "_blank");
    }
  };
  // const handleLogoutDrop = () => {
  //   setIsActive(!isActive);
  // };

  return (
    <div>
      <div>
        <header className="header-wrapper mobile-header-active">
          <div className="header-logo">
            <img
              src={Logo}
              className="logo"
              alt="logo"
              onClick={() => navigate("/")}
            />
          </div>

          <div className="nav-links">
            <ul>
              <li>
                <a
                  className={`footer_sider_bar`}
                  href="https://console.ai.ml/dashboard"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="https://console.ai.ml/playground/chat"
                  className={`footer_sider_bar`}
                >
                  Playground
                </a>
              </li>
              <li>
                <a
                  href="https://console.ai.ml/setting/yourOrganisation"
                  className={`footer_sider_bar `}
                >
                  Settings
                </a>
              </li>
            </ul>
          </div>
          <div className="user-settings-main">
            <div
              onClick={() => navigateAPIDoc("community")}
              className="docs-cum"
            >
              Community
            </div>
            <div onClick={() => navigateAPIDoc("docs")} className="docs-cum">
              Docs
            </div>
            {/* <div className="logout-wrap">
              <img
                src={Logout}
                onClick={handleLogoutDrop}
                className="logout-icon"
                alt="logoutIcon"
              />

              {isActive ? (
                <ul className="drop-list">
                  <li
                        className="drop-item"
                        onClick={handleProfileBalanceClick}
                      >
                        Wallet
                      </li> 
                  <li className="drop-item" onClick={handleLogout}>
                        Logout
                      </li> 
                </ul>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </header>
      </div>
      <div className="privacy-n-termCondition">
        <div className="termCondition-container">
          <div className="aiml-card">
            <div className="ailm-card-header">
              <div>
                <h3 className="page-title">Terms of Service</h3>
                <p className="page-date"></p>
              </div>
              <div>
                <button
                  type="bytton"
                  className="aiml-btn"
                  onClick={() => navigate("/")}
                >
                  Close
                </button>
              </div>
            </div>
            <div className="aiml-card-body">
              <h4 className="content-title">1. Introduction</h4>
              <p className="content-text">
                Welcome to the NeevCloud GenAI Inference Platform. By accessing
                or using our platform, you agree to comply with and be bound by
                these Terms of Service (TOS). Please read them carefully. If you
                do not agree to these terms, please do not use the platform.
              </p>
              <h4 className="content-title">2. Definitions</h4>
              <p className="content-text">
                <span>“Platform”</span> refers to the NeevCloud GenAI Inference
                Platform, including all associated services and features,
                accessible at https://ai.ml/.
              </p>
              <p className="content-text">
                <span>“User”</span> refers to any individual or entity accessing
                or using the platform.
              </p>
              <p className="content-text">
                <span>“Content”</span> refers to any data, information, text,
                images, video, audio, or other material submitted, uploaded, or
                generated by users through the platform.
              </p>
              <p className="content-text">
                <span>“Service”</span> refers to the AI/ML inference services
                provided by the platform.
              </p>
              <h5 className="content-subtitle">2.1 Personal Information</h5>
              <p className="content-text">
                Account Information: When you create an account, we collect
                information such as your name, email address, phone number, and
                other contact details.
              </p>
              <p className="content-text">
                Profile Information: You may provide additional information,
                such as a profile picture and other personal details.
              </p>
              <h4 className="content-title">3. Use of the Platform</h4>
              <p className="content-text">
                Users must be at least 18 years old to use the platform.
              </p>
              <p className="content-text">
                Users are responsible for maintaining the confidentiality of
                their account information and for all activities that occur
                under their account.
              </p>
              <p className="content-text">
                Users must provide accurate and complete information when
                creating an account and keep their account information updated.
              </p>
              <h4 className="content-title">4. User Conduct</h4>
              <p className="content-text">
                Users agree not to use the platform for any unlawful, harmful,
                or abusive purposes.
              </p>
              <p className="content-text">
                Users must not interfere with or disrupt the platform’s
                functionality, servers, or networks.
              </p>
              <p className="content-text">
                Is harmful, abusive, defamatory, or harassing.
              </p>
              <p className="content-text">
                Contains obscene, pornographic, or otherwise inappropriate
                material.
              </p>
              <p className="content-text">
                Promotes violence, hate speech, or discrimination against any
                individual or group.
              </p>
              <p className="content-text">
                Violates any applicable laws or regulations.
              </p>
              <p className="content-text">
                Infringes on the intellectual property or other rights of any
                third party.
              </p>
              <h4 className="content-title">
                5. Content and Intellectual Property
              </h4>
              <p className="content-text">
                Users retain ownership of any content they submit, upload, or
                generate on the platform. However, by submitting content, users
                grant NeevCloud a non-exclusive, royalty-free, worldwide license
                to use, store, display, reproduce, and distribute the content
                for the purpose of operating and improving the platform.
              </p>
              <p className="cpontent-text">
                NeevCloud retains all rights, title, and interest in and to the
                platform, including all related intellectual property rights.
              </p>
              <h4 className="content-title">6. Privacy</h4>
              <p className="content-text">
                We implement appropriate technical and organizational measures
                to protect your information from unauthorized access, use, or
                disclosure. However, no method of transmission over the internet
                or electronic storage is completely secure, and we cannot
                guarantee the absolute security of your information.
              </p>
              <h4 className="content-title">6. Data Retention</h4>
              <p className="content-text">
                Users’ privacy is important to us. Our Privacy Policy explains
                how we collect, use, and protect users’ personal information. By
                using the platform, users agree to the terms of our Privacy
                Policy.
              </p>
              <h4 className="content-title">7. Disclaimer of Warranties</h4>
              <p className="content-text">
                The platform is provided “as is” and “as available” without any
                warranties of any kind, whether express or implied.
              </p>
              <p className="content-text">
                NeevCloud does not warrant that the platform will be
                uninterrupted, error-free, or free from viruses or other harmful
                components.
              </p>
              <p className="content-text">
                Users use the platform at their own risk.
              </p>
              <h4 className="content-title">8. Limitation of Liability</h4>
              <p className="content-text">
                To the fullest extent permitted by law, NeevCloud shall not be
                liable for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenues, whether
                incurred directly or indirectly, or any loss of data, use,
                goodwill, or other intangible losses, resulting from:
              </p>
              <p className="content-text">
                Users’ use of or inability to use the platform.
              </p>
              <p className="content-text">
                Any unauthorized access to or use of users’ content.
              </p>
              <p className="content-text">
                Any interruption or cessation of transmission to or from the
                platform.
              </p>
              <h4 className="content-title">9. Indemnification</h4>
              <p className="content-text">
                Users agree to indemnify, defend, and hold harmless NeevCloud,
                its affiliates, and their respective officers, directors,
                employees, and agents from and against any claims, liabilities,
                damages, losses, and expenses, including reasonable attorneys’
                fees, arising out of or in any way connected with:
              </p>
              <p className="content-text">
                Users’ access to or use of the platform.
              </p>
              <p className="content-text">Users’ violation of these TOS.</p>
              <p className="content-text">
                Users’ infringement of any intellectual property or other rights
                of any third party.
              </p>
              <h4 className="content-title">10. Termination</h4>
              <p className="content-text">
                NeevCloud reserves the right to suspend or terminate users’
                access to the platform at any time, without notice, for any
                reason, including but not limited to, breach of these TOS.
              </p>
              <p className="content-text">
                Upon termination, all rights granted to users under these TOS
                will cease immediately.
              </p>

              <h4 className="content-title">11. Modifications to the TOS</h4>
              <p className="content-text">
                NeevCloud reserves the right to modify these TOS at any time.
                Users will be notified of any changes, and continued use of the
                platform after such modifications constitutes acceptance of the
                updated TOS.
              </p>

              <h4 className="content-title">12. Governing Law</h4>
              <p className="content-text">
                These TOS shall be governed by and construed in accordance with
                the laws of the jurisdiction in which NeevCloud is established,
                without regard to its conflict of law principles.
              </p>

              <h4 className="content-title">13. Contact Information</h4>
              <p className="content-text">
                For any questions or concerns regarding these TOS, please
                contact us at support@ai.ml. By using the NeevCloud GenAI
                Inference Platform, users acknowledge that they have read,
                understood, and agree to be bound by these Terms of Service.
              </p>
            </div>
            <div className="card-footer d-flex">
              <a href="mailto:support@ai.ml" className="aiml-primary-btn">
                <img src={Phone} alt="icon" />
                Contact Sales
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaoutTermsAndCondition;
