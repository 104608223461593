import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Waitlist from "./Waitlist/Waitlist";
import LayoutPrivacy from "./components/LayoutPrivacy";
import LaoutTermsAndCondition from "./components/LaoutTermsAndCondition";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Waitlist />} />
          <Route path="/privacy" element={<LayoutPrivacy />} />
          <Route path="/tos" element={<LaoutTermsAndCondition />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
